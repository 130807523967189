<template>
  <form :data-vv-scope="id" :key="id">
    <div class="person-form row">
      <!-- Title-->
      <div class="col-6 mb-6 pr-0 pl-1" v-if="hasSlot('title')">
        <slot name="title"></slot>
      </div>
      <div class="col-6 mb-6 pr-1 pl-1  ">
        <dual-switch
          v-bind:options="formTypeOptions"
          v-bind:selectedItem="formType"
          v-on:set-option="setFormType"
           />
      </div>

      <div class="col-12 no-padding">
        <div class="row" v-show="!isShowForm">
          <form-person-select v-model="value" :person-list="personList" v-on:onPersonSelected="onPersonSelected" />
        </div>
        <div class="row" v-show="isShowForm">
          <!-- Informar Depois      -->
          <div class="col-md-12 mt-4 mb-3" v-if="allowEmpty">
            <md-checkbox v-model="informarDepois">{{ $t('Informar depois')}}</md-checkbox>
          </div>

          <!-- Nome -->
          <div class="col-md-12 mt-4 mb-3" v-if="!informarDepois">
            <custom-input
              v-model="value.nome"
              :label="$t('form.person.nome')"
              name="value.nome"
              type="text"
              rootClassName="form-group mb-0"
              v-validate="{ required: true }"
              :error="errors.first('value.nome')">
            </custom-input>
          </div>

          <!-- País -->
          <div class="col-12 col-md-6 form-group mt-4  mb-3" v-if="!informarDepois">
            <label>{{ $t('form.person.pais')}}</label>
            <v-select name="value.pais" :key="id + 'value.pais'" label="nome" :clearable="false"
              :class="{ 'v-select-error': errors.first('value.pais') }"
              :searchable="true"  v-model="value.pais"  :options="countryList" :filter-by="nomeFilterBy"
              @input="onSelectCountry"  v-validate="{ required: true }" >
              <template slot="no-options" slot-scope="{searching, search }">
                <em v-if="!searching" style="opacity: 0.5;">
                  Digite o Nome para buscar
                </em>
                <em v-else style="opacity: 0.5;">
                  Nenhum país encontrado para <i>{{ search }}</i>.
                </em>
              </template>

              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.nome }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.nome }}
                </div>
              </template>
            </v-select>
            <transition name="slide" mode="">
              <small v-if="errors.first('value.pais')" class="royalc-error-field">{{ errors.first('value.pais') }}</small>
            </transition>
          </div>

          <!-- CEP -->
          <div v-show="isCEP" class="col-12 col-md-6 mt-4 mb-3" v-if="!informarDepois">
            <custom-input
              v-model="value.cep"
              :label="$t('form.person.cepZipcode')"
              name="value.cep"
              type="text"
              rootClassName="form-group mb-0"
              v-validate="{ required: isCEP }"
              :v-mask="{ mask: '[99999-999]', clearIncomplete: true}"
              @input="onCepInput"
              :error="errors.first('value.cep')">
            </custom-input>
          </div>

          <div v-show="!isCEP" class="col-12 col-md-6 mt-4 mb-3" v-if="!informarDepois">
            <custom-input
              v-model="value.cep"
              :label="$t('form.person.cepZipcode')"
              name="value.cep_exterior"
              type="text"
              rootClassName="form-group mb-0"
              @input="onCepInput"
              :error="errors.first('value.cep')">
            </custom-input>
          </div>

          <!-- Estado -->
          <div v-show="isCEP" class="col-12 col-md-6 form-group mt-4  mb-3" :key="id + 'value.estado'" v-if="!informarDepois">
            <label>{{ $t('form.person.estado')}}</label>
            <v-select name="value.estado" label="nome" :disabled="!(value.pais && value.pais.id !== null)"
              :class="{ 'v-select-error': errors.first('value.estado') }"
              :clearable="false" :searchable="true"  v-model="value.estado"  :options="stateList" :filter-by="nomeFilterBy"
              @input="onSelectState"  v-validate="{ required: (value.pais && value.pais.id == 22) }" >
              <template slot="no-options" slot-scope="{searching, search }">
                <em v-if="!searching" style="opacity: 0.5;">
                  Digite para buscar um estado
                </em>
                <em v-else style="opacity: 0.5;">
                  Nenhum estado encontrado para {{ search }}
                </em>
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.nome }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.nome }}
                </div>
              </template>
            </v-select>
            <transition name="slide" mode="">
              <small v-if="errors.first('value.estado')" class="royalc-error-field">{{ errors.first('value.estado') }}</small>
            </transition>
          </div>
          <div v-show="!isCEP" class="col-12 col-md-6 form-group mt-4 mb-3" v-if="!informarDepois">
            <custom-input
              v-model="value.ufExterior"
              :label="$t('form.person.estado')"
              name="value.ufExterior"
              type="text"
              rootClassName="form-group mb-0"
              v-validate="{ required: false }"
              :error="errors.first('value.ufExterior')">
            </custom-input>
            <transition name="slide" mode="">
              <small v-if="errors.first('value.ufExterior')" class="royalc-error-field">{{ errors.first('value.ufExterior') }}</small>
            </transition>
          </div>

          <!-- Município -->
          <div v-show="isCEP" class="col-12 col-md-6 form-group mt-4  mb-3" :key="id + 'value.cidade'" v-if="!informarDepois">
            <label>{{ $t('form.person.cidade')}}</label>
            <v-select name="value.cidade" :disabled="!(value.estado && value.estado.id !== null)" label="nome"  :clearable="false"
                      :class="{ 'v-select-error': errors.first('value.cidade') }"
                      :searchable="true"  v-model="value.cidade"  :options="cityList" @search="cityFetchOptions"
                      :filter-by="nomeFilterBy"
                      v-validate="{ required: (value.pais != null && value.pais.id == 22) }" >
              <template slot="no-options" slot-scope="{searching, search }">
                <em v-if="!searching" style="opacity: 0.5;">
                  Digite para buscar uma cidade
                </em>
                <em v-else style="opacity: 0.5;">
                  Nenhuma cidade encontrada para {{ search }}
                </em>
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.nome }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.nome }}
                </div>
              </template>
            </v-select>
            <transition name="slide" mode="">
              <small v-if="errors.first('value.cidade')" class="royalc-error-field">{{ errors.first('value.cidade') }}</small>
            </transition>
          </div>
          <div v-show="!isCEP" class="col-12 col-md-6 form-group mt-4 mb-3" v-if="!informarDepois">
            <custom-input
              v-model="value.municipioExterior"
              :label="$t('form.person.cidade')"
              name="value.municipioExterior"
              type="text"
              rootClassName="form-group mb-0"
              v-validate="{ required: false }"
              :error="errors.first('value.municipioExterior')">
            </custom-input>
            <transition name="slide" mode="">
              <small v-if="errors.first('value.municipioExterior')" class="royalc-error-field">{{ errors.first('value.municipioExterior') }}</small>
            </transition>
          </div>

          <!-- Endereço -->
          <div class="col-12 col-md-12 mt-4 mb-3" v-if="!informarDepois">
            <custom-input
              v-model="value.logradouro"
              :label="$t('form.person.logradouro')"
              name="value.logradouro"
              type="text"
              rootClassName="form-group mb-0"
              v-validate="{ required: true }"
              :error="errors.first('value.logradouro')">
            </custom-input>
          </div>

          <!-- CNPJ/VatNumber -->
          <div class="col-md-12 mt-4 mb-3" v-if="!informarDepois" :key="componentKey">
            <custom-input
              v-model="value.cnpj_vatnumber"
              :label="$t('form.person.cnpj_vatnumber')"
              name="value.cnpj_vatnumber"
              type="text"
              rootClassName="form-group mb-0"
              v-validate="{ required: true }"
              :v-mask="maskChecker()"
              :error="errors.first('value.cnpj_vatnumber')">
            </custom-input>
          </div>

          <!-- Contatos -->
          <div class="col-12 col-md-12 mt-4" v-if="!informarDepois">
            <legend>Contatos</legend>
            <form-person-contact  v-for="(contato, index) in value.contatos"
                                  v-model="value.contatos[index]" :index="index"
                                  v-bind:key="index"
                                  v-on:onAddContact="addContact"
                                  v-on:onRemoveContact="removeContact"
                                  :ref="id + 'contato'"

            />
          </div>

          <!-- Salvar como favorito-->
          <div class="col-md-12 mt-4 mb-3">
            <md-checkbox v-model="value.favorito">{{ $t('Salvar como favorito')}}</md-checkbox>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// @ is an alias to /src
import VeeValidate from 'vee-validate'
import { locale, validCNPJ } from '@/utils/validator'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'
import FormPersonContact from './FormPersonContact'
import DualSwitch from '../../components/Forms/DualSwitch.vue'
import FormPersonSelect from './FormPersonSelect'

// Select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import AddressService from '@/services/AddressService'
import ProposalService from '@/services/ProposalService'
import BaseService from '@/services/BaseService'

import Vue from 'vue'

VeeValidate.Validator.localize({ 'pt_BR': locale })
// Inclusão de cláusula de validação de tamanho máximo para imagem
VeeValidate.Validator.extend('validCNPJ', validCNPJ)
Vue.use(VeeValidate, { locale: 'pt_BR', fieldsBagName: 'formFields' })

export default {
  name: 'FormPerson',
  data () {
    return {
      loadCNPJInput: false,
      componentKey: 0,
      formType: {
        id: 1,
        name: 'NOVO'
      },
      formTypeOptions: [
        {
          id: 1,
          name: 'NOVO'
        },
        {
          id: 2,
          name: 'FAVORITOS'
        }
      ],
      informarDepois: false,
      id: null,
      cityList: [],
      copyFrom: {
        id: null,
        nome: 'Copiar dados de...'
      },
      copyFromList: [
        // {
        //   id: null,
        //   nome: 'Copiar dados de...'
        // },
        {
          id: 1,
          nome: 'Exportador'
        },
        {
          id: 2,
          nome: 'Importador'
        }
      ]
    }
  },
  components: {
    vSelect,
    CustomInput,
    FormPersonContact,
    DualSwitch,
    FormPersonSelect
  },
  computed: {
    isCEP () {
      return (!this.value.pais || this.value.pais.id === null || this.value.pais.id === 22)
    },
    isShowForm () {
      let showForm = false
      if (!this.formType || !this.formType.hasOwnProperty('id') || this.formType.id !== 2) {
        showForm = true
      } else if (this.formType || this.formType.hasOwnProperty('id') || this.formType.id === 2) {
        if (this.value.hasOwnProperty('id') && this.value.id > 0) {
          showForm = true
        }
      }

      return showForm
    }
  },
  props: {
    allowEmpty: {
      type: Boolean,
      required: false,
      default: false
    },
    proposalId: {
      type: Number,
      required: true,
      default: 0
    },
    personType: {
      type: String,
      required: true
    },
    countryList: {
      type: Array,
      required: true
    },
    stateList: {
      type: Array,
      required: true
    },
    personList: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    onCepInput (val) {
      let _this = this
      if (_this.isCEP) {
        let cep = val.replace(/[^0-9]/g, '')

        if (cep.length === 8) {
          _this.$emit('onLoading', true)

          BaseService.getCEP(cep).then(function (res) {
            let cepInfo = res.data.data
            if (cepInfo) {
              let state = _this.stateList.find(e => e.sigla === (cepInfo.uf))

              if (state) {
                _this.value.estado = state
                _this.$emit('onLoading', true)
                AddressService.getCities({ state: state.id, per_page: 500 }).then(res => {
                  _this.cityList = res.data.data
                  if (cepInfo.localidade) {
                    let city = _this.cityList.find(e => {
                      let localidade = cepInfo.localidade.toLowerCase()
                      localidade = localidade.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

                      let nome = e.nome.toLowerCase()
                      nome = nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

                      return (nome.indexOf(localidade) > -1)
                    })

                    if (city) {
                      _this.value.cidade = city
                    }

                    let logradouro = []

                    if (cepInfo.logradouro) {
                      logradouro.push(cepInfo.logradouro)
                    }

                    if (cepInfo.bairro) {
                      logradouro.push(cepInfo.bairro)
                    }
                    _this.value.logradouro = logradouro.join(', ')
                  }
                  _this.$emit('onLoading', false)
                }).catch(() => {
                  _this.$emit('onLoading', false)
                })
              } else {
                _this.$emit('onLoading', false)
              }
            } else {
              _this.$emit('onLoading', false)
            }
          }).catch(() => {
            _this.$emit('onLoading', false)
          })
        }
      }
    },
    onPersonSelected (val) {
      let _this = this
      /* Se Selecionada uma pessoa existente, preenche com os dados anteriores
      */
      if (val.hasOwnProperty('id')) {
        _this.isLoading = true

        this.value.id = val.id
        this.value.cnpj_vatnumber = val.cnpj_vatnumber
        this.value.cep = val.cep
        this.value.logradouro = val.logradouro
        this.value.telefone = val.telefone
        this.value.email = val.email
        this.value.pais = { id: val.pais_id, nome: val.pais_nome }
        this.value.estado = (val.estado_id) ? { id: val.estado_id, nome: val.estado_nome } : null
        this.value.cidade = (val.municipio_id) ? { id: val.municipio_id, nome: val.municipio_nome } : null
        this.value.ufExterior = (val.ufExterior) ? val.ufExterior : null
        this.value.municipioExterior = (val.municipioExterior) ? val.municipioExterior : null
        this.value.nome = val.nome
        this.value.favorito = val.favorito

        ProposalService.getPersonContacts(val.id).then((res) => {
          _this.value.contatos = res.data.data
          _this.isLoading = false
        })

        this.componentKey += 1
      }
    },
    maskChecker () {
      if (this.value && this.value.pais && this.value.pais.hasOwnProperty('id')) {
        if (this.value.pais.id === 22) {
          return {
            mask: '[99.999.999/9999-99]',
            clearIncomplete: true,
            showMaskOnFocus: false,
            showMaskOnHover: false
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    setFormType (obj) {
      this.formType = obj
      this.clearPerson(this.value)
    },
    clearPerson (val) {
      val.id = null
      val.cnpj_vatnumber = null
      val.nome = null
      val.pais = null
      val.estado = null
      val.cidade = null
      val.ufExterior = null
      val.municipioExterior = null
      val.cep = null
      val.logradouro = null
      val.email = null
      val.telefone = null
      val.favorito = null
      val.contatos = [
        {
          nome: null,
          email: null,
          fone: null
        }
      ]
    },
    addContact (index) {
      let _this = this
      // Clone object to not change _this.task by reference
      let contact = {
        nome: null,
        email: null,
        fone: null
      }

      // Adicionar após
      index++

      _this.value.contatos.splice(index, 0, contact)
    },
    removeContact (index) {
      let _this = this
      _this.value.contatos.splice(index, 1)

      // Se for o último adiciona um vazio
      if (_this.value.contatos.length === 0) {
        let contact = {
          nome: null,
          email: null,
          fone: null
        }
        _this.value.contatos.push(contact)
      }
    },
    nomeFilterBy (option, label, search) {
      let temp = search.toLowerCase()

      return (this.$util.slugify(option.nome).toLowerCase().indexOf(this.$util.slugify(temp)) > -1)
    },
    onLoading (val) {
      this.$emit('onLoading', val)
    },
    validate () {
      let _this = this
      _this.onLoading(true)

      if (_this.informarDepois) {
        return new Promise((resolve, reject) => {
          _this.clearPerson(_this.value)
          _this.onLoading(false)
          resolve(true)
        })
      } else {
        return new Promise((resolve, reject) => {
          _this.$validator.validateAll(_this.id).then((result) => {
            let formContactValidators = []
            let formContactObjects = _this.$refs[_this.id + 'contato']
            for (let formContact in formContactObjects) {
              formContactValidators.push(formContactObjects[formContact].validate())
            }
            _this.$util.scrollToTop()
            Promise.all(formContactValidators)
              .then(results => {
                let hasError = results.find((el) => {
                  return el === false
                })

                // Se tudo ocorreu bem salva a pessoa e seus contatos
                if (hasError === undefined) {
                  if (this.value.id) {
                    ProposalService.putPerson(this.value).then((res) => {
                      _this.value.id = res.data.data.id
                      _this.$emit('onPersonUpdated', _this.personType)
                      ProposalService.putStepPerson(_this.proposalId, _this.personType, { id: _this.value.id }).then((res) => {
                        _this.onLoading(false)
                        resolve(true)
                      })
                    }).catch(() => {
                      _this.onLoading(false)
                      resolve(false)
                    })
                  } else {
                    ProposalService.postPerson(_this.value).then((res) => {
                      _this.value.id = res.data.data.id
                      ProposalService.putStepPerson(_this.proposalId, _this.personType, { id: _this.value.id }).then((res) => {
                        _this.onLoading(false)
                        resolve(true)
                      })
                    }).catch(() => {
                      _this.onLoading(false)
                      resolve(false)
                    })
                  }
                } else {
                  this.$util.scrollToFirstError()
                  _this.onLoading(false)
                  resolve(false)
                }
              }).catch(() => {
                resolve(false)
              })
          })
        })
      }
    },
    hasSlot (name = 'default') {
      return !!this.$slots[ name ] || !!this.$scopedSlots[ name ]
    },
    onSelectPerson (val) {
      let _this = this
      /* Se CNPJ ou VATNumber limpo "X"
         Limpa os dados
      */
      if (!val) {
        _this.clearPerson(_this.value)
      } else {
        /* Se Selecionada uma pessoa existente, preenche com os dados anteriores
        */
        if (val.hasOwnProperty('id')) {
          _this.isLoading = true

          this.value.id = val.id
          this.value.cnpj_vatnumber = val.cnpj_vatnumber
          this.value.cep = val.cep
          this.value.logradouro = val.logradouro
          this.value.telefone = val.telefone
          this.value.email = val.email
          this.value.pais = { id: val.pais_id, nome: val.pais_nome }
          this.value.estado = (val.estado_id) ? { id: val.estado_id, nome: val.estado_nome } : null
          this.value.cidade = (val.municipio_id) ? { id: val.municipio_id, nome: val.municipio_nome } : null
          this.value.ufExterior = (val.ufExterior) ? val.ufExterior : null
          this.value.municipioExterior = (val.municipioExterior) ? val.municipioExterior : null
          this.value.nome = val.nome
          this.value.favorito = val.favorito

          ProposalService.getPersonContacts(val.id).then((res) => {
            _this.value.contatos = res.data.data
            _this.isLoading = false
          })
        } else {
          _this.clearPerson(_this.value)
          _this.value.cnpj_vatnumber = val.cnpj_vatnumber
        }
      }
    },
    onSelectCountry (val) {
      let _this = this
      // _this.isLoading = true
      // Clear previous fields
      _this.value.estado = null
      _this.value.cidade = null
      _this.value.cep = null

      this.componentKey += 1
    },
    onSelectState (val) {
      let _this = this
      _this.isLoading = true
      // Clear previous Fields
      _this.value.cidade = null

      AddressService.getCities({ state: val.id, per_page: 500 }).then(res => {
        _this.cityList = res.data.data
      }).finally(() => {
        _this.isLoading = false
      })
    },

    // Load basilares data
    cityFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'state': _this.value.estado.id,
        'per_page': 100,
        'searchTerm': search
      }

      AddressService.getCities(filters).then(res => {
        _this.cityList = res.data.data
        loading(false)
      })
    }
  },
  created () {
    this.id = 'form_' + this._uid
  },
  watch: {
    value: function (newVal, oldVal) {
      if (newVal.id > 0 && newVal.favorito) {
        this.formType = {
          id: 2,
          name: 'FAVORITOS'
        }
      }
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .option-disabled {
    cursor: not-allowed;
    color: #83dadb;
  }
</style>
