<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="light-gray-box">
      <h4 class="subtitle">
        {{ $t('Adicione os contatos que receberão a cópia da proposta de pré-reserva.') }}
      </h4>
      <div>
        <h6>{{ $t('Clique em concluir para finalizar!')}}</h6>
      </div>
    </div>

    <!-- Contatos -->
    <div class="content-box">
      <div class="row">
        <div class="col-12">
          <div class="font-14 mb-3">
            {{ $t('Contatos que receberão uma cópia') }}
          </div>
        </div>
      </div>
      <div class="row py-2" v-for="contact in contacts" v-bind:key="contact.id">
        <div class="col-lg-4 btn-m-auto py-1">
          <span class="">{{ contact.nome }}</span>
        </div>

        <div class="col-lg-7 btn-m-auto py-1">
          <span class="contact-email">{{ contact.email }}</span>
        </div>

        <div class="col-lg-1 btn-m-auto py-1">
          <a class="btn btn-link px-0 mx-0 pull-right" :title="$t('Não enviar e-mail para esse contato')"
            v-on:click="onDelete(contact.id)">
            <i class="text-primary ti-trash font-20"></i>
          </a>
        </div>
      </div>
      <div class="row" v-if="!showNewContactForm">
        <div v-on:click="addNewContact" class="col-12 btn-link text-right">
          Adicionar novo contato
        </div>
      </div>

      <!-- Novo Contato - Form-->
      <form :data-vv-scope="id" v-if="showNewContactForm">
        <div class="row">
          <!-- Nome -->
          <div class="col-12 col-lg-6 mt-4 mb-3">
            <custom-input
              v-model="formData.nome"
              :label="$t('form.contact.name')"
              name="contact.nome"
              type="text"
              v-validate="{ required: true }"
              :error="errors.first('contact.nome')">
            </custom-input>
          </div>

          <!-- Contato Email -->
          <div class="col-12 col-lg-6 mt-4 mb-3 pr-0">
            <custom-input
              :label="$t('form.person.email')"
              v-model="formData.email"
              name="contact.email"
              type="text"
              v-validate="{ required: true, email: true }"
              :error="errors.first('contact.email')">
            </custom-input>
          </div>

          <div class="col-12 btn-m-auto text-right pr-0">
            <button class="btn btn-link" href="#" @click.prevent="onCancelNewContact">Cancelar</button>
            <button class="btn btn-primary" href="#" @click.prevent="onSave">Adicionar contato</button>
          </div>
        </div>
      </form>
    </div>

    <!-- Action Box -->
    <div class="action-box pull-right">
      <button type="button" v-if="!showSuccess" class="btn btn-link btn-sm mt-1" @click="onClose">
        Cancelar
      </button>

      <button type="button" class="btn btn-primary" @click="onSend" v-if="!showNewContactForm">
        Concluir
      </button>
    </div>

  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'

// Services
import ProposalService from '@/services/ProposalService'

export default {
  name: 'ContactModal',
  data () {
    return {
      showNewContactForm: false,
      id: null,
      isLoading: true,
      fullPage: true,
      contacts: [],
      formData: {
        nome: null,
        email: null
      }
    }
  },
  components: {
    Loading,
    CustomInput
  },
  created () {
    let _this = this
    _this.id = 'form_' + _this._uid

    _this.isLoading = true

    ProposalService.getContacts(_this.proposal_id).then(response => {
      _this.contacts = response.data.data
    }).finally(() => {
      _this.isLoading = false
    })
  },
  methods: {
    addNewContact () {
      let _this = this
      _this.showNewContactForm = true
    },
    onSend () {
      let _this = this
      _this.isLoading = true

      ProposalService.postSend(_this.proposal_id).then(res => {
        _this.isLoading = false
        _this.onClose()
      }).catch(() => {
        _this.isLoading = false
      })
    },
    onDelete (contactId) {
      let _this = this

      _this.isLoading = true

      ProposalService.deleteContact(_this.proposal_id, contactId).then(res => {
        ProposalService.getContacts(_this.proposal_id).then(response => {
          _this.contacts = response.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }).catch(() => {
        _this.isLoading = false
      })
    },
    onClose () {
      this.$emit('close')
    },
    onCancelNewContact () {
      let _this = this
      _this.formData.nome = null
      _this.formData.email = null
      _this.showNewContactForm = false
    },
    onSave () {
      let _this = this

      _this.$validator.validateAll(_this.id).then((result) => {
        if (result) {
          _this.isLoading = true
          ProposalService.addContact(_this.proposal_id, _this.formData).then(response => {
            _this.formData.nome = null
            _this.formData.email = null
            _this.showNewContactForm = false
            ProposalService.getContacts(_this.proposal_id).then(response => {
              _this.contacts = response.data.data
            }).finally(() => {
              _this.isLoading = false
            })
          }).catch(() => {
            _this.isLoading = false
          })
        }
      })
    },
    onCancel () {
      this.$emit('onCancel')
      this.onClose()
    }
  },
  props: {
    proposal_id: {
      type: Number,
      required: true
    },
    showSuccess: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>
  .light-gray-box {
    background-color: #F7F7F7;
    padding: 35px 40px 17px 40px;
  }

  .content-box {
    padding: 20px 40px 17px 40px;
  }

  .action-box {
    padding: 40px 40px 35px 40px;
  }

  .btn-m-auto {
    margin: auto;
  }

  .contact-email {
    color: grey;
  }

  .plus-signal {
    cursor: pointer;
  }
</style>
