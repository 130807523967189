<template>
  <form :data-vv-scope="id" :key="id">
    <div class="row">
      <!-- Contato Nome -->
      <div class="col-12 col-md-4 mt-4 p-0 mb-0">
      <custom-input
        v-model="value.nome"
        :label="$t('form.person.contato_nome')"
        name="contato.nome"
        type="text"
        rootClassName="form-group mb-0"
        v-validate="{ required: true }"
        :error="errors.first('contato.nome')">
      </custom-input>
      </div>

      <!-- Contato Email -->
      <div class="col-12 col-md-5 mt-4 p-0 pl-2 mb-0">
        <custom-input
          :label="$t('form.person.email')"
          v-model="value.email"
          name="contato.email"
          type="text"
          rootClassName="form-group mb-0"
          v-validate="{ required: true, email: true }"
          :error="errors.first('contato.email')"/>
      </div>

      <!-- Contato Telefone -->
      <div class="col-12 col-md-3 mt-4 p-0 pl-2 mb-0">
        <custom-input
          v-model="value.fone"
          :label="$t('form.person.telefone')"
          name="contato.fone"
          type="text"
          rootClassName="form-group mb-0"
          v-validate="{ required: false }"
          :error="errors.first('contato.fone')">
        </custom-input>
      </div>

      <!-- Ações -->
      <div class="col-12 mt-0 mb-3">
        <a class="btn btn-link btn-sm pull-right m-0 p-1" @click="removeContact(index)" tabindex="">
          <md-icon>remove_circle_outline</md-icon>
        </a>
        <a class="btn btn-link btn-sm pull-right m-0 p-1" @click="addContact(index)" tabindex="">
          <md-icon>add_circle_outline</md-icon>
        </a>
      </div>
    </div>
  </form>
</template>

<script>
// @ is an alias to /src

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'

export default {
  name: 'FormPersonContact',
  data () {
    return {
      id: null
    }
  },
  components: {
    CustomInput
  },
  computed: {
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
    addContact (index) {
      this.$emit('onAddContact', index)
    },
    removeContact (index) {
      this.$emit('onRemoveContact', index)
    },
    validate () {
      let _this = this

      return new Promise((resolve, reject) => {
        _this.$validator.validateAll(_this.id).then((result) => {
          resolve(result)
        })
      })
    }
  },
  created () {
    this.id = 'form_' + this._uid
  }
}
</script>

<style scoped>
</style>
