<template>
  <div class="dual-switch-master-slot">
    <div class="row dual-switch-items-wrap p-0 m-0">
      <div class="col-6 dual-switch-item left-item py-1"
        :class="{selected: selectedObject.id == options[0].id}" @click="setOption(0)">
        <div class="dual-switch-item-middle py-1">
          <span class="small span-label span-left ">{{ options[0].name }}</span>
        </div>
      </div>
      <div class="col-6 dual-switch-item right-item py-1"
        :class="{selected: selectedObject.id == options[1].id}" @click="setOption(1)">
        <div class="dual-switch-item-middle">
          <span class="span-label span-right">{{ options[1].name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
  name: 'DualSwitch',
  props: {
    name: String,
    options: {
      type: Array,
      default: function () {
        return [
          {
            id: 0,
            name: 'OFF'
          },
          {
            id: 1,
            name: 'ON'
          }
        ]
      }
    },
    selectedItem: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null,
      required: false
    }
  },
  methods: {
    setOption (index) {
      if (!this.disabled) {
        this.$emit('set-option', this.options[index])
      }
    }
  },
  computed: {
    selectedObject () {
      if (this.selectedItem == null) {
        return this.options[0]
      }

      return this.selectedItem
    }
  }
}
</script>

<style lang="scss" scoped>
.dual-switch-master-slot {
  width: 100%;
  height: 100% !important;
  padding-top: 15px;
  // vertical-align: bottom;
  // background-color: red;
  // margin: auto !important;
  // display: block;
}

.dual-switch-items-wrap {
  background-color: #726752;
  color: white;
  width: 100%;
  // height: 100%;
  vertical-align: middle;
  border-radius: 18px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: -2px 2px 5px 1px silver;
  // background-color: yellow;
}

.dual-switch-item {
  border-radius: 18px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // background-color: purple;
}

.dual-switch-item-middle {
  width: 100%;
}

.left-item {
}

.right-item {
}

.span-label {
  font-size: smaller;
}

.span-left {
}

.span-right {
}

.left-item.selected {
}

.right-item.selected {
}

.selected {
  background-color: white !important;
  color: #726752 !important;
}

@media (max-width: 768px) {
  .dual-switch-master-slot {
    padding-top: 1vh;
    padding-top: 1vh;
  }
}
</style>
