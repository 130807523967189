<template>
  <form :data-vv-scope="id" :key="id">
    <div class="up-sell-form row">
      <!-- Title-->
      <div class="col-12 mb-6 pr-0 pl-1" v-if="hasSlot('title')">
         <slot name="title"></slot>
      </div>

      <!-- Seguro -->
      <div class="col-md-12 mt-4 mb-3">
        <insurance-card-up-sell :proposalId="proposalId" :moedaFrete="moedaFrete"  v-model="value.seguro" ref="adicionaisSeguro" v-on:onLoading="onLoading">
          <template slot="title">
            {{ $t('form.proposal.adicionais.seguro.nome')}}
          </template>
        </insurance-card-up-sell>
      </div>

      <!-- Complementar -->
      <div class="col-md-12 mt-4 mb-3">
        <notes-card-up-sell :proposalId="proposalId" v-model="value.complementar" ref="adicionaisComplementar" v-on:onLoading="onLoading">
          <template slot="title">
            {{ $t('form.proposal.adicionais.complementar.nome')}}
          </template>
        </notes-card-up-sell>
      </div>
    </div>
  </form>
</template>

<script>
// @ is an alias to /src

// Form
import InsuranceCardUpSell from './InsuranceCardUpSell'
import NotesCardUpSell from './NotesCardUpSell'

export default {
  name: 'FormUpSell',
  data () {
    return {
      id: null,
      stateList: [],
      cityList: []
    }
  },
  components: {
    NotesCardUpSell,
    InsuranceCardUpSell
  },
  computed: {
  },
  props: {
    proposalId: {
      type: Number,
      required: true,
      default: 0
    },
    value: {
      type: Object,
      required: true
    },
    moedaFrete: {
      type: String,
      required: true
    }
  },
  methods: {
    onLoading (val) {
      this.$emit('onLoading', val)
    },
    validate () {
      let _this = this

      let refToValidate = _this.$refs['adicionaisSeguro']

      return refToValidate.validate()
    },
    hasSlot (name = 'default') {
      return !!this.$slots[ name ] || !!this.$scopedSlots[ name ]
    }
  },
  created () {
    this.id = 'form_' + this._uid
  }
  // mounted () {
  //   if (this.$hj) {
  //     this.$hj('stateChange', '/proposal/' + this.value.proposalId + '/create/upsell')
  //   }
  // }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }
</style>
