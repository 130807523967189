<template>
  <form :data-vv-scope="id" class="col-12 no-padding" :key="id">
    <div class="person-form-select row">
      <!-- Filter Field -->
      <div class="py-1 col-md-12">
        <div class="md-form mb-0">
          <div class="input-group-icon input-group-icon-right mb-0">
            <div class="md-form mb-0">
                <span class="input-icon input-icon-right">
                  <i class="ti-search"></i>
                </span>
              <input
                ref="searchTerm"
                class="form-control" type="text"
                v-model="searchTerm"
              >
            </div>
          </div>
        </div>
      </div>

      <button class="col-12 col-md-12 form-group select-option no-button" v-on:click.prevent="onPersonSelected(person)" v-for="(person, index) in optionsFilter" v-bind:key="index">
        <div class="row">
          <loading :active.sync="isLoading"
                   :can-cancel="false"
                   :is-full-page="fullPage"></loading>

          <div class="col-12 col-md-7">
            {{ person.nome }}
          </div>
          <div class="col-12 col-md-5">
            {{person.cnpj_vatnumber}}
          </div>
        </div>
      </button>

      <div class="col-12 col-md-12 form-group not-options" v-if="optionsFilter.length <= 0">
        <div class="row">
          <div class="col-12">
            Nenhum favorito encontrado para <strong>{{ searchTerm }}</strong>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// @ is an alias to /src
import VeeValidate from 'vee-validate'
import { locale, validCNPJ } from '@/utils/validator'

// Components
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Services

import Vue from 'vue'

VeeValidate.Validator.localize({ 'pt_BR': locale })
// Inclusão de cláusula de validação de tamanho máximo para imagem
VeeValidate.Validator.extend('validCNPJ', validCNPJ)
Vue.use(VeeValidate, { locale: 'pt_BR', fieldsBagName: 'formFields' })

export default {
  name: 'FormPersonSelect',
  data () {
    return {
      id: null,
      isLoading: false,
      fullPage: false,
      searchTerm: null
    }
  },
  components: {
    Loading
  },
  props: {
    delayTime: {
      type: Number,
      default: 1000
    },
    personList: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    onPersonSelected (val) {
      this.$emit('onPersonSelected', val)
    }
  },
  computed: {
    optionsFilter () {
      let persons = []

      if (this.searchTerm) {
        let temp = this.searchTerm.toLowerCase()
        persons = this.personList.filter((option) => {
          return (this.$util.slugify(option.nome).toLowerCase().indexOf(this.$util.slugify(temp)) > -1 || this.$util.slugify(option.cnpj_vatnumber).toLowerCase().indexOf(this.$util.slugify(temp)) > -1)
        })
      } else {
        persons = this.personList
      }

      return persons
    }
  },
  created () {
    this.id = 'form_' + this._uid
  }
}
</script>

<style scoped>
  .no-button {
    background-color: inherit;
    border: none;
    margin: 0;
    padding: 0;
  }

  .select-option, .not-options {
    border-bottom: 1px solid #dee2e6;
    margin-right: 15px;
    margin-left: 15px;
    padding-bottom: 2.1428rem;
    padding-top: 2.1428rem;
  }

  .not-options {
    font-style: italic;
    color: #a7acae;
  }

  .select-option:hover, .select-option:hover div {
    cursor: pointer;
    font-family: 'Pluto Sans Medium';
  }

  .select-option:focus {
    color: #495057;
    background-color: #fff;
    border-color: #2949EF;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(41, 73, 239, 0.25);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(41, 73, 239, 0.25);
  }

  .select-option:focus, .select-option:focus div {
    font-family: 'Pluto Sans Medium';

  }
</style>
