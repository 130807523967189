<template>
  <form :data-vv-scope="id" :key="id">
    <div class="row card-up-sell" :class="{checked: true}">
      <!-- Title-->
      <div class="col-9 pr-0 pl-1" v-if="hasSlot('title')">
         <h3 class="title"><slot name="title"></slot></h3>
      </div>

      <div class="col-3 pr-0 pl-1 pull">
<!--        <div class="select icon text-center pull-right">-->
<!--          <i class="fa fa-check"></i>-->
<!--        </div>-->
      </div>

      <!-- Detalhes -->
      <div class="col-12 pr-0 pl-1 pull">
        <div class="row">
          <md-field>
            <md-textarea v-model="value"  @input="handleInput"></md-textarea>
          </md-field>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// @ is an alias to /src
import { Validator } from 'vee-validate'

// Form

// Services
import ProposalService from '@/services/ProposalService'

Validator.extend('min_formated_value', {
  getMessage: field => 'Valor precisa ser maior que 0,00.',
  validate: (value) => {
    if (!value) {
      return false
    }

    value = value.replaceAll('.', '')
    value = value.replaceAll(',', '.')
    value = parseFloat(value)

    return value > 0
  }
})

export default {
  name: 'NotesCardUpSell',
  computed: {
  },
  data () {
    return {
      id: null
    }
  },
  components: {
  },
  props: {
    proposalId: {
      type: Number,
      required: true,
      default: 0
    },
    value: {
      type: String,
      required: true
    }
  },
  methods: {
    onLoading (val) {
      this.$emit('onLoading', val)
    },
    handleInput (e) {
      this.$emit('input', this.value)
    },
    validate () {
      let _this = this
      _this.onLoading(true)
      return new Promise((resolve, reject) => {
        _this.$validator.validateAll(this.id).then((result) => {
          if (result) {
            ProposalService.putStepUpSell(_this.proposalId, _this.value).then((res) => {
              _this.onLoading(false)
              resolve(result)
            })
          } else {
            _this.$util.scrollToFirstError()
            _this.onLoading(false)
            resolve(result)
          }
        })
      })
    },
    hasSlot (name = 'default') {
      return !!this.$slots[ name ] || !!this.$scopedSlots[ name ]
    }
  },
  created () {
    this.id = 'form_' + this._uid
  },
  watch: {
  }
}
</script>

<style scoped>
.card-up-sell {
  border: 1px solid #efede8;
  -webkit-border-radius: 0.6rem;
  border-radius: 0.6rem;
  padding: 25px;
  /* box-shadow: -1px 1px 5px 1px lightgrey; */
}

.card-up-sell.checked {
  -webkit-border-radius: 0.6rem;
  border-radius: 0.6rem;
  background-color: #fff;
  padding: 25px;

}

.select.icon {
  height: 30px;
  width: 30px;
  padding-top: 6px;
  color:  #84754e;
  border: 1px solid;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}

.checked .select.icon {
  color: #fff;
  background-color: #84754e;
}
</style>
