<template>
  <form :data-vv-scope="id" :key="id">
    <div class="row card-up-sell" :class="{checked: value.freteMercadoria}">
      <!-- Title-->
      <div class="col-9 pr-0 pl-1" v-if="hasSlot('title')">
         <h3 class="title"><slot name="title"></slot></h3>
      </div>

      <div class="col-3 pr-0 pl-1 pull">
        <div class="select icon text-center pull-right" v-on:click="toggle">
          <i v-if="value" class="fa fa-check"></i>
          <i v-else class="fa fa-plus"></i>
        </div>
      </div>

      <div class="col-12 col-md-6 pr-0 pl-1">
        {{ (getValorSeguro) ? $util.formatNumber($util.getNumberFromFormated(getValorSeguro)) : '' }}
      </div>

      <!-- Detalhes -->
      <div class="col-12 pr-0 pl-1 pull" v-show="value.freteMercadoria">
        <div class="row">
          <!-- Valor do Frete -->
          <div class="col-12 col-md-6 mt-4 mb-3">
            <custom-input
              v-model="value.valor_frete"
              :label="$t('form.proposal.adicionais.seguro.valor_frete', { moeda: moedaFrete })"
              name="value.valor_frete"
              type="text"
              rootClassName="form-group mb-0"
              :disabled="true"
              :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
              >
            </custom-input>
          </div>

          <!-- Valor Mercadoria -->
          <div class="col-12 col-md-6 mt-4 mb-3">
            <custom-input
              v-model="value.valor_mercadoria"
              :label="$t('form.proposal.adicionais.seguro.valor_mercadoria', { moeda: moedaFrete })"
              name="value.valor_mercadoria"
              type="text"
              rootClassName="form-group mb-0"
              :disabled="!value.freteMercadoria"
              v-validate="valorMercadoriaValidationRules"
              :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
              :error="errors.first('value.valor_mercadoria')">
            </custom-input>
          </div>

          <div class="col-12 mb-3">
            Deseja ampliar seu seguro para que ele contemple :
          </div>
          <div class="col-12 mb-0">
            <md-checkbox v-model="value.impostos" :disabled="!value.freteMercadoria">{{ $t('form.proposal.adicionais.seguro.impostos')}} <span>
                <i class="ft-alert-circle"></i><md-tooltip md-direction="right">{{ $t('form.proposal.adicionais.seguro.impostos_helper')}}</md-tooltip>
              </span>
            </md-checkbox>
          </div>
          <div class="col-12 mb-0">
            <md-checkbox v-model="value.outras_despesas" :disabled="!value.freteMercadoria">{{ $t('form.proposal.adicionais.seguro.outras_despesas')}} <span>
                <i class="ft-alert-circle"></i><md-tooltip md-direction="right">{{ $t('form.proposal.adicionais.seguro.outras_despesas_helper')}}</md-tooltip>
              </span>
            </md-checkbox>
          </div>
        </div>
      </div>

    </div>
  </form>
</template>

<script>
// @ is an alias to /src
import { Validator } from 'vee-validate'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'

// Services
import AddressService from '@/services/AddressService'
import ProposalService from '@/services/ProposalService'

Validator.extend('min_formated_value', {
  getMessage: field => 'Valor precisa ser maior que 0,00.',
  validate: (value) => {
    if (!value) {
      return false
    }

    value = value.replaceAll('.', '')
    value = value.replaceAll(',', '.')
    value = parseFloat(value)

    return value > 0
  }
})

export default {
  name: 'InsuranceCardUpSell',
  computed: {
    valorMercadoriaValidationRules () {
      if (this.value.freteMercadoria) {
        return { required: true, 'min_formated_value': 0.0 }
      } else {
        return { required: false }
      }
    },
    getValorSeguro () {
      if (!this.value.freteMercadoria || this.value.valor_mercadoria <= 0) {
        return ''
      }

      let total = 0

      let valorMercadoria = this.$util.getNumberFromFormated(this.value.valor_mercadoria)
      let valorFrete = this.value.valor_frete

      total = (valorFrete + valorMercadoria)
      total = total * 0.0026

      // Add 10%
      if (this.value.impostos) {
        total = total + total * 0.1
      }

      // Add 10%
      if (this.value.outras_despesas) {
        total = total + total * 0.1
      }

      return total
    }
  },
  data () {
    return {
      id: null,
      stateList: [],
      cityList: []
    }
  },
  components: {
    CustomInput
  },
  props: {
    proposalId: {
      type: Number,
      required: true,
      default: 0
    },
    value: {
      type: Object,
      required: true
    },
    moedaFrete: {
      type: String,
      required: true
    }
  },
  methods: {
    onLoading (val) {
      this.$emit('onLoading', val)
    },
    update (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    toggle () {
      this.update('freteMercadoria', !this.value.freteMercadoria)
    },
    validate () {
      let _this = this
      _this.onLoading(true)
      return new Promise((resolve, reject) => {
        _this.$validator.validateAll(this.id).then((result) => {
          if (result) {
            ProposalService.putStepUpSell(_this.proposalId, _this.value).then((res) => {
              _this.onLoading(false)
              resolve(result)
            })
          } else {
            _this.$util.scrollToFirstError()
            _this.onLoading(false)
            resolve(result)
          }
        })
      })
    },
    hasSlot (name = 'default') {
      return !!this.$slots[ name ] || !!this.$scopedSlots[ name ]
    },
    onSelectState (val) {
      let _this = this
      _this.isLoading = true
      AddressService.getCities({ state: val.id, per_page: 500 }).then(res => {
        _this.cityList = res.data.data
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    // Load basilares data
    /**
     * Triggered when the origin search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    stateFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'country': _this.value.pais.id,
        'per_page': 100,
        'searchTerm': search
      }

      AddressService.getUFs(filters).then(res => {
        _this.stateList = res.data.data
        loading(false)
      })
    }
  },
  created () {
    this.id = 'form_' + this._uid
  },
  watch: {
    getValorSeguro: {
      deep: true,
      handler: function (newVal) {
        this.value.valor_seguro = newVal
      }
    }
  }
}
</script>

<style scoped>
.card-up-sell {
  border: 1px solid #efede8;
  -webkit-border-radius: 0.6rem;
  border-radius: 0.6rem;
  padding: 25px;
  /* box-shadow: -1px 1px 5px 1px lightgrey; */
}

.card-up-sell.checked {
  -webkit-border-radius: 0.6rem;
  border-radius: 0.6rem;
  background-color: #fff;
  padding: 25px;

}

.select.icon {
  height: 30px;
  width: 30px;
  padding-top: 6px;
  color:  #84754e;
  border: 1px solid;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}

.checked .select.icon {
  color: #fff;
  background-color: #84754e;
}
</style>
