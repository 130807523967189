<template>
  <form :data-vv-scope="id" :key="id">
    <div class="person-form row">
      <!-- Title-->
      <div class="col-12 mb-6 pr-0 pl-1" v-if="hasSlot('title')">
         <slot name="title"></slot>
      </div>
      <!-- Carga Segura e não pessoal -->
      <div class="col-12 col-md-12 mt-4 mb-3">
        <md-checkbox name="value.carga.segura_naopessoal" v-model="value.carga.segura_naopessoal"
          v-validate="{ required: true }">{{ $t('form.proposal.carga.segura_naopessoal') }}</md-checkbox>
        <transition name="slide" mode="">
          <small v-if="errors.first('value.carga.segura_naopessoal')"
            class="royalc-error-field">{{ errors.first('value.carga.segura_naopessoal') }}</small>
        </transition>
      </div>

      <!-- Referência Cliente -->
      <div class="col-12 mt-4 mb-3" v-show="!form_disabled">
        <custom-input
          v-model="value.referencia_cliente"
          :label="$t('form.proposal.referencia_cliente_opcional')"
          name="value.referencia_cliente"
          :helperText="$t('form.proposal.referencia_cliente_help')"
          type="text"
          v-validate="{ required: false }"
          :error="errors.first('value.referencia_cliente')"
          :disabled="form_disabled"
        >
        </custom-input>
      </div>

      <!-- Anexar Comercial Invoice -->
      <div class="col-12 col-md-12 mt-4 mb-3" v-show="!form_disabled">
        <md-checkbox name="value.comercial_invoice" v-model="value.comercial_invoice"
                     v-validate="{ required: false }">Anexar Comercial Invoice. Os dados da carga, exportador, importador e notify serão obtidos da comercial invoice anexada.</md-checkbox>
        <transition name="slide" mode="">
          <small v-if="errors.first('value.comercial_invoice')"
                 class="royalc-error-field">{{ errors.first('value.comercial_invoice') }}</small>
        </transition>
      </div>

      <!--  Attach -->
      <div class="col-12 col-md-12 mb-3" v-show="!form_disabled && isComercialInvoice">
        <custom-file-upload
          v-model="value.arquivos"
          name="value.arquivos"
          acceptedFiles="application/pdf,.pdf"
          v-validate="{ required: true }"
          :max-files="1"
          :disabled="form_disabled || !isComercialInvoice"
          :error="errors.first('value.arquivos')">
        </custom-file-upload>
      </div>

      <!-- NCM -->
      <div class="col-12 col-md-12 form-group mt-4  mb-3" v-show="!form_disabled && !isComercialInvoice">
        <label>{{ $t('form.proposal.carga.ncm')}}</label>
        <v-select name="value.carga.ncm" label="codigo"  :clearable="false" :searchable="true"
          v-model="value.carga.ncm"  :options="ncmList" @search="ncmFetchOptions"
          :filter-by="ncmFilter" v-validate="{ required: true }" :disabled="form_disabled || isComercialInvoice"
        >
          <template slot="no-options" slot-scope="{searching, search }">
            <em v-if="!searching" style="opacity: 0.5;">
              Digite o nome da mercadoria ou código para buscar
            </em>
            <em v-else style="opacity: 0.5;">
              Nenhum NCM encontrado para {{ search }}
            </em>
          </template>

          <template slot="option" slot-scope="option">
            <div class="d-center">
              {{ $util.formatNcm(option.codigo) }} - {{ option.descricao }}
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ $util.formatNcm(option.codigo) }} - {{ option.descricao }}
            </div>
          </template>
        </v-select>
        <transition name="slide" mode="">
          <small v-if="errors.first('value.carga.ncm')" class="royalc-error-field">{{ errors.first('value.carga.ncm') }}</small>
        </transition>
      </div>

      <!-- Descrição Mercadoria -->
      <div class="col-md-12 mt-4 mb-3" v-show="!form_disabled && !isComercialInvoice">
        <custom-input
          :label="$t('form.proposal.carga.descricao')"
          v-model="value.carga.descricao"
          name="value.carga.descricao"
          type="text"
          rootClassName="form-group mb-0"
          v-validate="{ required: true }"
          :error="errors.first('value.carga.descricao')"
          :disabled="form_disabled || isComercialInvoice">
        </custom-input>
      </div>

      <!-- Quantidade de Volumes -->
      <div class="col-12 col-md-6 mt-4 mb-3" v-show="!form_disabled && !isComercialInvoice">
        <custom-input
          v-model="value.carga.quantidade"
          :label="$t('form.proposal.carga.quantidade')"
          name="value.carga.quantidade"
          type="text"
          rootClassName="form-group mb-0"
          v-validate="{ required: true }"
          :error="errors.first('value.carga.quantidade')"
          :disabled="form_disabled || isComercialInvoice">
        </custom-input>
      </div>

      <!-- Tipo de Volume -->
      <div class="col-12 col-md-6 form-group mt-4  mb-3" v-show="!form_disabled && !isComercialInvoice">
        <label>{{$t('form.proposal.carga.tipo_volume')}}</label>
        <v-select name="value.carga.tipo_volume" label="nome"  :clearable="false" :searchable="true"
          v-model="value.carga.tipo_volume"  :options="tipoEmbalagemList"
          @search="tipoEmbalagemFetchOptions" v-validate="{ required: false }" :disabled="form_disabled || isComercialInvoice">
          <template slot="option" slot-scope="option">
            <div class="d-center">
              {{ option.nome }}
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.nome }}
            </div>
          </template>
        </v-select>
        <transition name="slide" mode="">
          <small v-if="errors.first('value.carga.tipo_volume')" class="royalc-error-field">{{ errors.first('value.carga.tipo_volume') }}</small>
        </transition>
      </div>

      <!-- Peso Bruto -->
      <div class="col-12 col-md-6 mt-4 mb-3" v-show="!form_disabled && showPesoBruto && !isComercialInvoice">
        <custom-input
          v-model="value.carga.peso_bruto"
          :label="$t('form.proposal.carga.peso_bruto')"
          :helperText="$t('form.proposal.carga.peso_bruto_help')"
          name="value.carga.peso_bruto"
          type="text"
          rootClassName="form-group mb-0"
          v-validate="{ required: true }"
          :v-mask="{ 'allowPlus':true, 'alias': 'currency', 'prefix': '', 'groupSeparator': '.', 'radixPoint':','}"
          :error="errors.first('value.carga.peso_bruto')"
          :disabled="form_disabled || isComercialInvoice">
        </custom-input>
      </div>
    </div>
  </form>
</template>

<script>
// @ is an alias to /src

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'
import CustomFileUpload from '@/components/Forms/CustomFileUpload.vue'

// Select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import NCMService from '@/services/NCMService'
// import CustomCheckBox from '@/components/Forms/CustomCheckBox'
import TipoEmbalagemService from '@/services/TipoEmbalagemService'
import ProposalService from '@/services/ProposalService'

export default {
  name: 'FormCargo',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      id: null,
      ncmList: [],
      tipoEmbalagemList: []
    }
  },
  components: {
    vSelect,
    CustomFileUpload,
    CustomInput
  },
  created () {
    let _this = this
    _this.id = 'form_' + _this._uid

    _this.isLoading = true

    // Load basilares data
    TipoEmbalagemService.getList({ per_page: 500 }).then(res => {
      _this.tipoEmbalagemList = res.data.data
    }).finally(() => {
      _this.isLoading = false
    })
  },
  watch: {
    value: function (newValue, oldValue) {
      if (this.$hj) {
        this.$hj('stateChange', '/proposal/' + newValue.id + '/create/cargo')
      }
    }
  },
  methods: {
    onLoading (val) {
      this.$emit('onLoading', val)
    },
    ncmFilter (option, label, search) {
      let searchNumbers = search.replace(/[^0-9]/g, '')
      let temp = search.toLowerCase()

      return (option.codigo.toLowerCase().indexOf(searchNumbers) > -1 || option.descricao.toLowerCase().indexOf(temp) > -1)
    },
    validate () {
      let _this = this
      _this.onLoading(true)
      return new Promise((resolve, reject) => {
        _this.$validator.validateAll(_this.id).then((result) => {
          _this.$util.scrollToTop()
          if (result) {
            ProposalService.putStepCargo(_this.value.id, _this.value).then((res) => {
              for (let i in this.value.arquivos) {
                let arquivo = this.value.arquivos[i]
                arquivo.temp = false
              }

              _this.$util.scrollToTop()
              _this.onLoading(false)
              resolve(result)
            })
          } else {
            this.$util.scrollToFirstError()
            _this.onLoading(false)
            resolve(result)
          }
        })
      })
    },
    hasSlot (name = 'default') {
      return !!this.$slots[ name ] || !!this.$scopedSlots[ name ]
    },
    // Load basilares data
    /**
     * Triggered when the origin search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    ncmFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'per_page': 100,
        'searchTerm': search
      }

      NCMService.getList(filters).then(res => {
        _this.ncmList = res.data.data
        loading(false)
      })
    },
    tipoEmbalagemFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'per_page': 100,
        'searchTerm': search
      }

      TipoEmbalagemService.getList(filters).then(res => {
        _this.tipoEmbalagemList = res.data.data
        loading(false)
      })
    }
  },
  computed: {
    isComercialInvoice () {
      if (this.value.comercial_invoice === true) {
        return true
      }

      return false
    },
    form_disabled () {
      return !this.value.carga.segura_naopessoal
    },
    showPesoBruto () {
      return this.value.tipo_carga !== 4
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }
</style>
