<template>
  <div>
    <!-- Contatos -->
    <div class="content-box">
      <div class="row">
        <div class="col-12">
          <div class="swal2-icon swal2-success swal2-animate-success-icon" style="display: flex;"><div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div><span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span><div class="swal2-success-ring"></div> <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div><div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div></div>
        </div>
        <div class="col-12">
          <h2 class="swal2-title text-center" id="swal2-title-pre-reserva-recebida">Pré-Reserva recebida</h2>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-12 btn-m-auto py-1">
          A partir de agora, nossos operadores estão trabalhando na sua solicitação, e em breve entrarão em contato para fornecer os detalhes de andamento do seu novo processo.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuccessModal',
  data () {
    return {
    }
  },
  created () {
    setTimeout(() => {
      this.$emit('close')
    }, 4000)
  },
  methods: {},
  props: {
  }
}
</script>

<style scoped>
  .light-gray-box {
    background-color: #F7F7F7;
    padding: 35px 40px 17px 40px;
  }

  .content-box {
    padding: 20px 40px 17px 40px;
  }

  .action-box {
    padding: 40px 40px 35px 40px;
  }

  .btn-m-auto {
    margin: auto;
  }

  .contact-email {
    color: grey;
  }

  .plus-signal {
    cursor: pointer;
  }
</style>
