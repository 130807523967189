<template>
  <div class="summary" v-if="proposal.total && proposal.total.length > 0">
    <div class="row">
      <div class="col-12">
        <h3>Informações</h3>
      </div>
      <!--  Serviço -->
      <div class="col-12" v-if="proposal.servico_com_transbordo">
        * Serviço com transbordo
      </div>

      <!--  Modalidade -->
      <div class="col-12">
        <div class="label">{{ $t('form.proposal.modality')}}</div>
        {{ modalidade }}
      </div>

      <!--  Data Início vig^encia -->
      <div class="col-md-6 col-12">
        <div class="label">{{ $t('Válido para embarque de')}}</div>
        {{ $util.formatDateWritten(proposal.data_inicio_vigencia) }} <br/>
      </div>

      <!--  Data Validade -->
      <div class="col-md-6 col-12">
        <div class="label">{{ $t('Válido para embarque até')}}</div>
        {{ $util.formatDateWritten(proposal.data_validade) }} <br/>
      </div>

      <!--   Origem   -->
      <div class="col-md-6">
        <div class="label">{{ $t('form.proposal.origin')}}</div>
        <div>{{proposal.origem.nome }}{{ (proposal.origem.pais) ? (', ' + proposal.origem.pais): '' }}</div>
      </div>

      <!--   Destino   -->
      <div class="col-md-6">
        <div class="label">{{ $t('form.proposal.destination')}}</div>
        <div>{{proposal.destino.nome }}{{ (proposal.destino.pais) ? (', ' + proposal.destino.pais): '' }}</div>
      </div>

      <!--   Peso Bruto   -->
      <div class="col-md-6" v-if="isLCL">
        <div class="label">{{ $t('form.proposal.carga.peso_bruto')}}</div>
        <div>{{ proposal.carga.peso_bruto }}</div>
      </div>

      <!--   CBM   -->
      <div class="col-md-6" v-if="isLCL">
        <div class="label">{{ $t('form.proposal.carga.cbm')}}</div>
        <div>{{ proposal.carga.cbm }}</div>
      </div>

      <!--   Equipamentos   -->
      <div class="col-12">
        <div  class="row" v-for="(equipamento) in proposal.equipamentos" v-bind:key="equipamento.id">
          <div class="col-md-6">
            <div class="label hidden-sm-down">{{ $t('form.proposal.equipments')}}</div>
            <div>{{ equipamento.descricao }}</div>
          </div>
          <div class="col-md-6">
            <div class="label hidden-sm-down">{{ $t('form.proposal.quantity')}}</div>
            <div>{{ equipamento.quantidade }}</div>
          </div>
        </div>
      </div>

    <!--   Exportador   -->
    <div class="col-lg-12 col-md-6 col-sm-12" v-if="proposal.exportador && proposal.exportador.nome">
      <div class="label">{{ $t('form.proposal.step.exportador')}}</div>
      <div>{{proposal.exportador.nome }} {{ (proposal.exportador.cnpj_vatnumber) ? ('(' + proposal.exportador.cnpj_vatnumber + ')') : null }}</div>
    </div>

    <!--   Importador   -->
    <div class="col-lg-12 col-md-6 col-sm-12" v-if="proposal.importador && proposal.importador.nome">
      <div class="label">{{ $t('form.proposal.step.importador')}}</div>
      <div>{{proposal.importador.nome }} {{ (proposal.importador.cnpj_vatnumber) ? ('(' + proposal.importador.cnpj_vatnumber + ')') : null }}</div>
    </div>

    <!--   Notify   -->
    <div class="col-lg-12 col-md-6 col-sm-12" v-if="proposal.notify && proposal.notify.nome">
      <div class="label">{{ $t('form.proposal.step.notify')}}</div>
      <div>{{proposal.notify.nome }} {{ (proposal.notify.cnpj_vatnumber) ? ('(' + proposal.notify.cnpj_vatnumber + ')') : null }}</div>
    </div>

    <!-- Total -->
    <div class="col-12 total">
      <div class="row pb-2">
        <!-- Desconto -->
        <div class="col-sm-6 label" v-if="isCupomAdded">
          <span class="pull-left">{{  $t('form.proposal.desconto')  }}</span>
        </div>
        <div class="col-sm-6 mb-3" v-if="isCupomAdded">
          <div class="w-100">
            <div class="currency w-100">
              <p class="w-100 p-0 m-0 text-right">
                <span>{{ proposal.cupom.moeda }}</span> {{ ($util.numberFormat(proposal.cupom.valor, 2, ',', '.')) }}
              </p>
            </div>
          </div>
        </div>

        <!-- Valor Total -->
        <div class="col-sm-6 label">
          <span class="pull-left">{{  $t('form.proposal.valor_cotacao')  }}</span>
        </div>
        <div class="col-sm-6">
          <div v-for="(moeda, index) in totalMoeda" v-bind:key="index" class="w-100">
            <div class="currency w-100">
              <p class="w-100 p-0 m-0 text-right" v-bind:class="{ 'total-negative': moeda.total < 0 }">
                <span v-if="index != 0">{{(moeda.total >=0) ? '+' : '-' }}</span>
                <span>{{ moeda.moeda }}</span> {{ moeda.total >= 0 ? ($util.numberFormat(moeda.total, 2, ',', '.')) : ($util.numberFormat(moeda.total * -1.0, 2, ',', '.')) }}

              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src

import ProposalService from '@/services/ProposalService'

export default {
  name: 'ProposalSummary',
  components: {
  },
  props: {
    proposal: {
      type: Object,
      required: true
    }
  },
  methods: {
    onLoading (val) {
      this.$emit('onLoading', val)
    },
    removeCupom () {
      this.onLoading(true)
      // Verificar se o cupom ainda está válido
      ProposalService.removeCupom(this.proposal.id).then((res) => {
        this.proposal.cupom.id = null
        this.proposal.cupom.valor = null
        this.proposal.cupom.moeda = null

        this.onLoading(false)
      }).catch(() => {
        this.onLoading(false)
      })
    }
  },
  computed: {
    modalidade () {
      if (this.isLCL) {
        return 'Marítimo - LCL'
      }
      return 'Marítimo - FCL'
    },
    isCupomAdded () {
      let isAdded = false

      if (this.proposal.cupom && this.proposal.cupom.id !== null) {
        isAdded = true
      }

      return isAdded
    },
    hasCupomAvailable () {
      let hasAvalaible = false

      if (this.availableCupom) {
        return true
      }

      return hasAvalaible
    },
    totalMoeda () {
      let total = []
      let valorSeguro = 0
      if (this.proposal.adicionais.seguro.freteMercadoria) {
        valorSeguro = this.proposal.adicionais.seguro.valor_seguro
      }

      for (let i in this.proposal.total) {
        let totalMoeda = this.proposal.total[i]
        let totalValor = totalMoeda.total

        total.push({
          moeda: totalMoeda.moeda,
          total: totalValor
        })
      }

      if (valorSeguro > 0) {
        let index = total.findIndex((e) => {
          return e.moeda === 'USD'
        })

        if (index !== -1) {
          total[index].total = total[index].total + valorSeguro
        } else {
          total.push({
            moeda: 'USD',
            total: valorSeguro
          })
        }
      }

      if (this.proposal.cupom.valor > 0) {
        let index = total.findIndex((e) => {
          return e.moeda === this.proposal.cupom.moeda
        })

        if (index !== -1) {
          total[index].total = total[index].total - this.proposal.cupom.valor
        } else {
          total.push({
            moeda: this.proposal.cupom.moeda,
            total: this.proposal.cupom.valor
          })
        }
      }

      return total
    },
    isLCL () {
      let isLcl = false

      if (this.proposal && this.proposal.tipo_carga === 4) {
        isLcl = true
      }

      return isLcl
    }
  },
  created () {
  }
}
</script>

<style>
.label {
  margin-bottom: 0px !important;
}

.custom-btn-link {
  color: #3483fa !important;
}

.custom-btn-link:hover {
  color: #2968c8 !important;
  cursor: pointer;
}

  .total-negative, .total-negative span {
    color:  red !important;
  }
</style>
